export default {
  // tabbar
  tabbarHeight: '80px',
  tabbarBackground: '#743A3A',
  tabbarItemActiveBackground: '#743A3A',
  tabbarItemIconSize: '22px',
  tabbarItemIconMarginBottom: '8px',
  // tabs
  tabsNavBackground: '#743A3A',
  tabTextColor: 'rgba(255, 255, 255, .6)',
  tabActiveTextColor: 'rgba(255, 255, 255, 1)',
  tabsBottomBarColor: 'rgba(255, 255, 255, 1)',
  // switch
  switchNodeBackground: '#743A3A',
  // navbar
  navBarIconColor: '#fff',
  navBarHeight: '52px',
  navBarBackground: '#743A3A',
  navBarZIndex: 2,
  // button
  stepperButtonRoundThemeColor: '#743A3A',
  // steps
  stepsBackground: '#743A3A',

  floatingBubbleBackground: '#743A3A',

  // tree-select
  sidebarSelectedBorderColor: '#743A3A',
  sidebarSelectedTextColor: '#743A3A',

  // card 商品
  cardPriceColor: '#743A3A',

  // dropdownMenu 下拉
  dropdownMenuTitleActiveTextColor: '#743A3A',
  dropdownMenuOptionActiveColor: '#743A3A',

  // action bar
  actionBarHeight: '60px',
}
